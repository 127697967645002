import * as React from "react";
import { graphql } from "gatsby";

import Page from "../components/Page";
import Skill from "../components/Skill";
import DefaultLayout from "../layouts/default";

import { TagType } from "../types";

import { MenuProvider } from '../hooks/Menu/Provider';

import { GatsbySharpFluidImage } from "../types";

export const query = graphql`
query SkillsPageQuery {
  tags: allTagsJson(sort: {fields: [stars, name], order: [DESC, ASC]}, filter: {show_in_skills: {eq: true}}) {
    nodes {
      name
      stars
      text
      id,
      show_in_skills
    }
  },
  backgroundImage: imageSharp(fluid: {originalName: {eq: "Skills.jpg"}}) {
    fluid: fluid(quality: 90, maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
    }
  }
}
`;

interface SkillsPageProps {
  data: {
    tags: {
      nodes: TagType[]
    },
    backgroundImage: GatsbySharpFluidImage
  }
}

const SkillsPage: React.FC<SkillsPageProps> = ({ data }) => {
  return (
    <MenuProvider>
      <DefaultLayout 
        title={"Skills"} 
        subtitle={["Ich liebe es, einen Teil eines Softwareprojekts zu sein und dieses zu begleiten, egal in welchem Stadium. Für mich ist „User first“ sehr wichtig - und das bereits seit mehr als 10 Jahren."]}
        backgroundImage={data.backgroundImage}
      >
        <Page>
          {data.tags.nodes.map((tag) => (<Skill key={tag.id} {...tag}/>))}
        </Page>
      </DefaultLayout>
    </MenuProvider>
  )
};

export default SkillsPage;
