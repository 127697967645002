import * as React from "react";
import styled from "styled-components";

import { SmallContainer } from "../components/content";

import StarActiveIcon from "../../static/icons/Star-Active.inline.svg";
import StarInactiveIcon from "../../static/icons/Star-Inactive.inline.svg";

import { fontTypes, breakpoints } from "../styles/variables";

const StarsContainer = styled.div`
    margin: 25px auto;
    width: 130px;
    @media (min-width: ${breakpoints.lg}px) {
        margin: 50px auto;
    }
    svg {
        margin: 0 6px 0 0;
    }

    p {
        ${fontTypes.supersmall};
        margin: 0;
    }
`;

const SkillContainer = styled(SmallContainer)`
    margin-bottom: 50px;   
    @media (min-width: ${breakpoints.lg}px) {
        margin-bottom: 100px;
    }
`;


interface SkillProps {
    stars: number
    name: string,
    text: string
}

const Skill: React.FC<SkillProps> = ({ stars, name, text }) => {
    let starsItems = [];
    for (let i =0; i <= 4; i++) {
        if(i < stars) starsItems.push(<StarActiveIcon key={"star-"+i}/>);
        else starsItems.push(<StarInactiveIcon key={"star-"+i}/>)
    }
    return (
        <SkillContainer>
            <h3>{name}</h3>
            <p>{text}</p>
            <StarsContainer>
                {starsItems}
                <p>Personal skill rating</p>
            </StarsContainer>
        </SkillContainer>
    )
};

export default Skill;